import { CHANNEL_TYPES, LOG_LEVEL, NOTIFICATION_TYPE, sendNotification, setPresence } from '@amc-technology/davinci-api';
import { getPresence, setPresence as setTeamsPresence } from '../GraphService';
import { registerOnLogout, registerOnPresenceChanged, setAppHeight, setSupportedChannels } from '@amc-technology/davinci-api';

import { AuthenticatedTemplate } from '@azure/msal-react';
import { CallComponent } from '../CallComponent';
import React from 'react';
import { TeamsCallAgent } from '@azure/communication-calling';
import UserComponent from '../UserComponent';
import bind from 'bind-decorator';
import './Home.css';

interface props {
  logger: any;
  csConfig: any;
  instance: any;
  user: UserComponent;
  callAgent: TeamsCallAgent;
  id: string
}

export class Home extends React.Component<props> {
  teamsCallAgent?: TeamsCallAgent;
  authProvider?: any;
  userId: string;
  davinciPresence = '';
  lastDavinciPresence = '';
  lastTeamsPresence = '';
  presencePollingInterval: any;

  constructor(props: any) {
    const functionName = 'constructor';
    super(props);
    try {
      this.authProvider = this.props.user.getAuthProvider();
      this.userId = this.props.user.getUserId();

      registerOnPresenceChanged(this.onDaVinciPresenceChanged);
      registerOnLogout(this.logout);
      setSupportedChannels([{
        channelType: CHANNEL_TYPES.Telephony,
        id: this.userId,
        idName: 'Microsoft Teams User Id',
        validOperations: []
      }]);

      this.createPresencePollingInterval();
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `Failed to handle agentDetails : ERROR`, error);
    } finally {
      this.resize();
    }
  }

  createPresencePollingInterval() {
    const functionName = 'createPresencePollingInterval';
    try {
      this.props.logger.log(LOG_LEVEL.Loop, functionName, 'Creating presence polling interval.', this.props.csConfig?.presencePollingInterval);
      this.presencePollingInterval = setInterval(this.pollForTeamsPresence, this.props.csConfig?.presencePollingInterval || 5000);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `Failed to handle agentDetails : ERROR`, error);
    }
  }

  async componentDidMount() {
    const functionName = 'componentDidMount';
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, 'Getting initialPresence');
      const presence = await getPresence(this.authProvider);
      this.props.logger.log(LOG_LEVEL.Trace, functionName, 'Presence', presence);
      const teamsPresence = presence.availability + '|' + presence.activity;
      const davinciPresence = this.props.csConfig?.channelToDaVinci[teamsPresence];
      if (davinciPresence && davinciPresence !== null && davinciPresence !== undefined && davinciPresence !== '') {
        this.lastTeamsPresence = presence.availability + '|' + presence.activity;
        setPresence(davinciPresence);
      } else {
        this.props.logger.log(LOG_LEVEL.Debug, functionName, 'Presence not found in channelToDaVinci.', { teamsPresence, channelToDaVinci: this.props.csConfig?.channelToDaVinci });
      }
      this.resize();
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `Failed to handle agentDetails : ERROR`, error);
    }
  }

  /**
   * Setting the Presence in teams to match a presence changed event coming from click to act
   *
   * @param {any} data - presence/reason data from click to act
   * @memberof CallComponent
   */
  async setPresenceToTeamsCTA(data: any): Promise<void> {
    const functionName = `setPresenceToTeamsCTA`;
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `Presence Changed event received.`, data);
      this.onDaVinciPresenceChanged(data.presence, data.reason, data.pending, true);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `Failed to handle presence change event.`, error);
    }
  }

  /**
   * Change presence in Microsoft teams if there is a change in presence that is not initiated from this application
   *
   * @param {string} daVinciPresence - Presence in DaVinci
   * @param {string} reason - Reason for a presence change if there is a reason
   * @param {string} initiatingApp - App that initiated the change in presence
   * @memberof CallComponent
   */
  @bind
  async onDaVinciPresenceChanged(presence: string, reason?: string, initiatingApp?: string, clickToAct: boolean = false): Promise<void> {
    const functionName = 'onDaVinciPresenceChanged';
    try {
      if (initiatingApp !== this.props.csConfig.appName && presence !== this.lastDavinciPresence) {
        this.props.logger.log(LOG_LEVEL.Debug, functionName, `Presence changed.`, { presence, reason, initiatingApp });
        const daVinciPresence = reason ? presence + '|' + reason : presence;
        if (daVinciPresence in this.props.csConfig?.DaVinciToChannel) {
          const teamsPresence = this.props.csConfig.DaVinciToChannel[daVinciPresence];
          if (teamsPresence && teamsPresence !== this.lastTeamsPresence && this.userId) {
            await this.setTeamsPresence(teamsPresence, this.userId, this.props.csConfig.clientID);
            setPresence(presence, reason);
          }
          this.lastDavinciPresence = daVinciPresence;
          this.props.logger.log(LOG_LEVEL.Trace, functionName, 'Presence mapped to Teams', { daVinciPresence, teamsPresence });
        } else {
          this.props.logger.log(LOG_LEVEL.Warning, functionName, `Presence not found in DaVinciToChannel.`, { daVinciPresence, DaVinciToChannel: this.props.csConfig?.DaVinciToChannel });
          sendNotification(`Presence ${daVinciPresence} missing from presence map.`, NOTIFICATION_TYPE.Warning);
        }
      }
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `ERROR`, error);
    }
  }

  @bind
  resize(minimumHeight = 0, maximumHeight = Infinity) {
    const functionName = 'Home.resize(): ';
    try {
      this.props.logger.log(LOG_LEVEL.Loop, functionName, "Start >>>");
      let currentHeight = document.getElementById('AMC-Teams-Root').scrollHeight;
      if (currentHeight < minimumHeight) {
        this.props.logger.log(LOG_LEVEL.Loop, functionName, 'Constraining App Height to minimum height', { currentHeight, minimumHeight });  
        currentHeight = minimumHeight;
      }
      if (currentHeight > maximumHeight) {
        this.props.logger.log(LOG_LEVEL.Loop, functionName, 'Constraining App Height to maximum height', { currentHeight, maximumHeight });  
        currentHeight = maximumHeight;
      }

      // Hard constraint for minimum app height from config
      if (this.props.csConfig?.minAppHeight !== null && 
          this.props.csConfig?.minAppHeight !== undefined &&
          currentHeight < this.props.csConfig.minAppHeight
         ) {
        this.props.logger.log(LOG_LEVEL.Loop, functionName, 'Constraining App Height to minimum height from config', { currentHeight, minimumHeight: this.props.csConfig?.minAppHeight });  
        currentHeight = this.props.csConfig.minAppHeight;
      }

      let finalHeight: number = currentHeight >= this.props.csConfig?.minAppHeight ? currentHeight : this.props.csConfig?.minAppHeight;

      this.props.logger.log(LOG_LEVEL.Loop, functionName, 'Setting App Height', { finalHeight, minHeight: this.props.csConfig?.minAppHeight });
      setAppHeight(finalHeight);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `ERROR`, error);
    }
    this.props.logger.log(LOG_LEVEL.Loop, functionName, "End >>>");
  }

  /**
   * Change teams presence
   *
   * @param {string} presence - Teams presence to be set
   * @param {string} userId - User id for user
   * @param {string} initiatingApp - App that initiated the change in presence
   * @memberof CallComponent
   */
  @bind
  async setTeamsPresence(presence: string, userId: string, clientId: string) {
    const functionName = 'setTeamsPresence';
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `Setting Presence in Teams`, presence);
      await setTeamsPresence(this.authProvider, presence.split('|'), userId, clientId);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `Failed to send presence to Teams`, error);
    } finally {
      this.lastTeamsPresence = presence;
    }
  }

  @bind
  async pollForTeamsPresence() {
    const functionName = 'pollForTeamsPresence';
    let presence: string;
    try {
      const teamsPresence = await getPresence(this.authProvider);
      presence = teamsPresence.availability + '|' + teamsPresence.activity;
      if (teamsPresence.availability && teamsPresence.activity && presence !== this.lastTeamsPresence) {
        this.props.logger.log(LOG_LEVEL.Trace, functionName, `Presence from Teams:`, presence);
        if (presence in this.props.csConfig?.channelToDaVinci) {
          const presenceArray = this.props.csConfig?.channelToDaVinci[presence].split('|');
          const davinciPresence = presenceArray[0];
          const davinciReason = presenceArray.length > 1 ? presenceArray[1] : '';
          this.props.logger.log(LOG_LEVEL.Debug, functionName, `Mapping Teams presence to Global Presence`, { TeamsPresence: presence, GlobalPresence: davinciPresence + '|' + davinciReason });
          this.lastDavinciPresence = davinciPresence + '|' + davinciReason;
          setPresence(davinciPresence, davinciReason);
        } else {
          this.props.logger.log(LOG_LEVEL.Warning, functionName, `Presence not found in channelToDaVinci.`, { presence, channelToDaVinci: this.props.csConfig?.channelToDaVinci });
          sendNotification(`Presence ${presence} missing from presence map.`, NOTIFICATION_TYPE.Warning);
        }
      }
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `Home Component : ERROR`, error);
    } finally {
      this.lastTeamsPresence = presence ?? this.lastTeamsPresence;
    }
  }

  @bind
  async logout() {
    const functionName = 'registerOnLogout';
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `Received Logout Event from Framework`);
      await this.props.callAgent?.dispose();
      await this.props.instance.logoutPopup();
      if (this.presencePollingInterval) {
        clearInterval(this.presencePollingInterval);
      }
      await this.props.logger.pushLogsAsync();
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `Logout Failed : ERROR`, error);
    }
  }

  render() {
    return (
      <div id = {this.props.id}>
        <AuthenticatedTemplate>
          { this.props.callAgent && (
            <CallComponent
              callAgent={this.props.callAgent}
              resize={this.resize}
              setTeamsPresence={this.setPresenceToTeamsCTA.bind(this)}
              logger={this.props.logger}
              transcriptionConfig={this.props.csConfig?.transcription}
              cognitiveServicesConfig={this.props.csConfig?.cognitiveServices}
              minAppHeight={this.props.csConfig?.minAppHeight}
              authProvider={this.authProvider}
              agentDetails={this.props.csConfig.userDetails}
              id="Home-CallComponent"
            />
          )}
        </AuthenticatedTemplate>
      </div>
    );
  }
}

export default Home;
